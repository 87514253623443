
 <template>
  <div>
    <!-- modal login-->
    <b-modal
      id="add-product"
      cancel-variant="outline-secondary"
      ok-title="Save"
      centered
      hide-footer="true"
      title="Add Product"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col> </b-col>
          <b-col>
            <div v-if="moreloading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>

            <b-link class="p-1">
              <b-img
                ref="previewEl"
                rounded
                thumbnail
                src="https://dk0pm9zdlq16s.cloudfront.net/add9143e-45af-4c67-a83a-842aa52b1121.png"
                @click="$refs.mfile.click()"
                v-if="moreloading == false"
                height="120"
                width="120"
              />

              <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
            </b-link>

            <input
              type="file"
              id="mfile"
              hidden
              ref="mfile"
              multiple="multiple"
              @change="selectMoreFile()"
              accept="image/*"
            />
          </b-col>
          <b-col> </b-col>
        </b-row>

        <b-row >
          <b-col class="mt-1" style="margin-left:2px;" cols="*" v-for="(img,index) in myObj.images" :key="img.id">
            <b-link>
              <div>
                <i
                  class="fa fa-trash fa-sm position-absolute m-5"
                  @click="removeImg(index)"
                ></i>
              </div>

              <div>
                <b-img rounded :src="img" height="120" width="120" />
              </div>
              <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
            </b-link>
            
          </b-col>
         
        </b-row>
         <div class="text-center" v-if="this.imgerr">
              <small class="text-danger ml-1">Image is Required.</small>
            </div>
        <div class="mt-1">
          <b-form-group
            label="SKU #"
            invalid-feedback="Product SKU number is required."
          >
            <b-form-input
              id="name-input"
              ref="sku"
              placeholder="Enter product SKU number here."
              required
              @focusout="CheckSKU()"
              v-model="myObj.sku"
            />
          </b-form-group>

          <b-form-group
            label="Name"
            invalid-feedback="Product name is required."
          >
            <b-form-input
              id="name-input"
              ref="name"
              v-model="myObj.name"
              @focusout="CheckName()"
              placeholder="Enter product name here."
              required
            />
          </b-form-group>

          <b-form-group
            label="Category"
            invalid-feedback="Category is required."
            ref="category"
          >
            <v-select
              v-model="myObj.categoryId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="categories"
              label="name"
              :clearable="false"
              input-id="id"
              ref="category"
               @search:blur="CheckCategory()"
              @input="LoadsubCategory()"
              :disabled="dataloading"
              placeholder="Select category"
            />
          </b-form-group>
          <b-form-group
            label="Sub category"
            invalid-feedback="Subcategory is required."
            ref="subcategory"
          >
            <v-select
              v-model="myObj.subcategoryId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="mysubCategories"
              label="name"
              :clearable="false"
              input-id="id"
              ref="subcategory"
               @search:blur="CheckSubCategory()"
              :disabled="dataloading"
              placeholder="Select sub category"
            />
          </b-form-group>
          <b-form-group label="Price" invalid-feedback="Price is required.">
            <b-form-input
              placeholder="Enter price here."
              required
              ref="price"
              v-model="myObj.price"
              @focusout="CheckPrice()"
            />
          </b-form-group>
          <b-form-group
            label="Available sizes / variants"
            invalid-feedback="Size is required."
            ref="sizes"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              v-model="myObj.sizes"
              :closeOnSelect="false"
              taggable
              ref="sizes"
               @search:blur="CheckSizes()"
              placeholder="Type size / variant and press enter."
            />
          </b-form-group>
          <b-row v-if="this.$store.state.userData.fittingRoom == true">
            <b-col cols="10">
              <p>Available for fitting room</p>
            </b-col>
            <b-col>
              <b-form-checkbox
                cols="2"
                style="margin-left: 4px"
                :checked="myObj.fittingRoom"
                v-model="myObj.fittingRoom"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-col>
          </b-row>

          <b-form-group
            label="Description"
            invalid-feedback="Description is required."
            ref="desc"
          >
            <b-form-textarea
              id="textarea-default"
              placeholder="Enter description here."
              v-model="myObj.description"
              rows="3"
              ref="desc"
              @focusout="CheckDescription()"
            />
          </b-form-group>
        </div>
      </b-form>
      <br />
      <hr />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddProduct()"
        >
          <span class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      centered
      @ok="Filter()"
      title="Filters"
    >
      <b-form>
        <b-form-group label="Select option">
          <!-- <h5>Select filter</h5> -->
          <v-select
            v-model="filterData"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productfilterOptions"
            label="text"
            :clearable="false"
            placeholder="Select Filter"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal id="modal-select2" size="lg" :title="detailObj.title" centered>
      <div v-if="loading == true">
        <b-spinner
          variant="primary"
          type="grow"
          style="margin-left: 350px"
          class="p-2"
          label="Spinning"
        ></b-spinner>
      </div>

      <div v-if="loading == false">
        <div>
          <b-container class="bv-example-row">
            <b-card>
              <b-row>
                <div lg="3" md="3" sm="12" cols="12">
                  <div class="profile-img pb-1">
                    <b-card-img
                      :src="detailObj.coverImg"
                      alt="Image"
                      class="rounded-1"
                      height="110px"
                    ></b-card-img>
                  </div>
                </div>

                <b-col lg="6" md="6" sm="12" cols="12">
                  <h3>{{ detailObj.title }}</h3>
                  <b-row>
                    <b-col>
                      <p>{{ detailObj.description }}</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="3" md="3" sm="12" cols="12">
                  <div class="">
                    <p v-if="detailObj.isApproved == 'pending'">
                      <b-button variant="gradient-warning" class="btn-icon">
                        Pending <feather-icon icon="TagIcon" />
                      </b-button>
                    </p>
                    <p v-if="detailObj.isApproved == 'suspended'">
                      <b-button variant="gradient-danger" class="btn-icon">
                        Suspended <feather-icon icon="TagIcon" />
                      </b-button>
                    </p>

                    <p v-if="detailObj.isApproved == 'true'">
                      <b-button variant="gradient-success" class="btn-icon">
                        Approved <feather-icon icon="CheckCircleIcon" />
                      </b-button>
                    </p>

                    <p v-if="detailObj.isApproved == 'false'">
                      <b-button variant="gradient-danger" class="btn-icon">
                        Denined <feather-icon icon="XCircleIcon" />
                      </b-button>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-card>
              <b-row>
                <b-col lg="4" md="4" cols="12">
                  <h3>Category</h3>
                </b-col>
                <b-col lg="4" md="4" cols="12">
                  <h3>Filling</h3>
                </b-col>
                <b-col lg="4" md="4" cols="12">
                  <h3>Days required</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="4" md="4" cols="12">
                  <p>{{ detailObj.Name }}</p>
                </b-col>
                <b-col lg="4" md="4" cols="12">
                  <p>{{ detailObj.fillingName }}</p>
                </b-col>
                <b-col lg="4" md="4" cols="12">
                  <p>{{ detailObj.minimumTime }}</p>
                </b-col>
              </b-row>
            </b-card>

            <b-card>
              <b-row>
                <b-col cols="12">
                  <h3>Flavours</h3>
                </b-col>
              </b-row>
              <b-row>
                <div v-for="flv in flavour[0]" v-bind:key="flv.flavourId">
                  <b-col cols="12">
                    <p>{{ flv.flavourName }}</p>
                  </b-col>
                </div>
              </b-row>
            </b-card>
            <b-card>
              <b-row>
                <b-col cols="12">
                  <h3>Sizes</h3>
                </b-col>
              </b-row>
              <b-row>
                <div v-for="flv in cakeSize[0]" v-bind:key="flv.sizeId">
                  <b-col cols="12">
                    <p>{{ flv.size }} - {{ flv.cost + 'AED' }}</p>
                  </b-col>
                </div>
              </b-row>
            </b-card>

            <div v-if="detailObj.totalLayer > 1">
              <b-card>
                <b-row>
                  <b-col lg="4" md="4" sm="4" cols="5">
                    <h3>Layers</h3>
                  </b-col>
                  <b-col lg="7" md="7" sm="7" cols="7">
                    <h3>Total ({{ detailObj.totalLayer }})</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div
                      v-for="flv in cakeLayers"
                      v-bind:key="flv.cakeLayersId"
                    >
                      <b-row>
                        <b-col>
                          <h6>Dummy layer</h6>
                          <p>{{ flv.dumyLayer }}</p>
                        </b-col>
                        <b-col>
                          <h6>Cake layer</h6>
                          <p>{{ flv.cakeLayer1 }}</p>
                        </b-col>
                        <b-col>
                          <h6>Layer price</h6>
                          <p>AED {{ flv.price }}</p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </div>

            <b-card>
              <b-row>
                <b-col cols="12">
                  <h3 style="margin-left: 5px">Images</h3>
                  <gallery
                    :images="cakeImages"
                    :index="index"
                    @close="index = null"
                  ></gallery>
                  <div
                    class="image rounded-1"
                    v-for="(image, imageIndex) in cakeImages"
                    :key="imageIndex"
                    @click="index = imageIndex"
                    :style="{
                      backgroundImage: 'url(' + image + ')',
                      width: '150px',
                      height: '110px',
                    }"
                  ></div>
                </b-col>
              </b-row>
            </b-card>
            <b-row>
              <b-col cols="4"> </b-col>

              <b-col cols="4">
                <b-button
                  variant="gradient-danger "
                  class="ml-4"
                  @click="DeleteProduct(detailObj.productId)"
                >
                  Delete
                </b-button>
              </b-col>
              <b-col cols="4"> </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </b-modal>

    <div no-body class="mb-2" style="padding: 20px" cols="12" md="4" sm="12">
      <div class="">
        <!-- Table Top -->
        <b-row align-h="center">
          <!-- Search -->

          <b-col  cols="*" >
            <b-button @click="EmptyForm()" variant="primary">
               <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
              <span class="text-nowrap">Add Product</span>
            </b-button>
          
             
          </b-col>
          <b-col cols="*" class="ml-1" >
     <b-button
              v-b-modal.modal-login
              variant="outline-primary"
                >
                 <feather-icon
        icon="FilterIcon"
        class="mr-50"
      />
                  <span class="text-nowrap">Filters</span>
                </b-button>
          </b-col>
        
          <b-col  xl="7" lg="7" md="5" class="mt-lg-0 mt-md-0 mt-sm-1 mt-1" >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              placeholder="Search..."
            />
          </b-col>

          <b-col  cols="1" class="mt-lg-0 mt-md-0 mt-sm-1 mt-1">
            <b-form-radio-group
              v-model="itemView"
              class="list item-view-radio-group"
              buttons
              size="sm"
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" size="20" />
              </b-form-radio>
            </b-form-radio-group>
          </b-col>

          <!-- Per Page -->
        </b-row>
      </div>
      <!-- :search-options="{
        enabled: true,
        externalQuery: searchTerm }" -->
      <!-- :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" -->
      <!--         
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }" -->
      <div class="mt-2" v-if="itemView == 'list-view'">
        <b-card>
          <!-- <b-row>
    <b-col cols="8">
          <b-button
      size="sm"
      class="mr-1 mb-1"
      variant="outline-primary"
      @click="selectAllRows"
    >
      Select all
    </b-button>
    <b-button
      size="sm"
      class="mr-1 mb-1"
         variant="outline-secondary"
      @click="clearSelected"
    >
      Clear selected
    </b-button>
    </b-col>
    <b-col cols="4">
      <b-button
      v-if="selected.length != 0"
      size="sm"
      class="mr-1 mb-1"
      variant="success"
      @click="ActiveAll('active')"
    >
      Active : {{selected.length }}
    </b-button>
     <b-button
      v-if="selected.length != 0"
      size="sm"
      class="mr-1 mb-1"
      variant="secondary"
      @click="ActiveAll('inactive')"
    >
     In Active : {{selected.length }}
    </b-button>
       <b-button
      v-if="selected.length != 0"
      size="sm"
      class="mr-1 mb-1"
      variant="danger"
      @click="DeletedAll(true)"
    >
     Delete : {{selected.length }} 
    </b-button>
    </b-col>
  </b-row> -->
   <!-- ref="selectableTable"
            selectable
            :select-mode="selectMode"
              @row-selected="onRowSelected" -->
          <b-table
            :tbody-tr-class="rowClass"
              striped
            :items="filterProducts"
            :fields="columns"
            responsive
          >
            <template #cell(cover)="data">
              <b-img
                :src="data.value"
                style="width: 75px; height: 75px"
                rounded
                alt="Rounded image"
              />
            </template>
            <template #cell(name)="data">
              <span>
                <strong> {{ data.item.name }} </strong>
              </span>
            </template>
            <template #cell(price)="data">
              <span>
                <strong> AED {{ data.item.price }} </strong>
              </span>
            </template>
            <template #cell(sizes)="data">
              <b-badge
                class="mr-1"
                style="margin-inline: 2px"
                v-for="i in data.item.sizes.split(',')"
                :key="i"
                variant="primary"
              >
                {{ i }}
              </b-badge>
            </template>
           
             <template #cell(status)="data">
                <b-badge
                      v-if="data.item.status == 'Approved'"
                      variant="success"
                      class="badge-glow"
                    >
                      Approved
                    </b-badge>
                    <b-badge
                      v-if="data.item.status == 'Pending'"
                      variant="secondary"
                      class="badge-glow"
                    >
                      Pending
                    </b-badge>
                    <b-badge
                      v-if="data.item.status == 'Suspended'"
                      variant="warning"
                      class="badge-glow"
                    >
                      Suspended
                    </b-badge>
                    <b-badge
                      v-if="data.item.status == 'Denied'"
                      variant="danger"
                      class="badge-glow"
                    >
                      Denied
                    </b-badge>

            </template>
 

            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                style="margin-left:5px;"
                v-b-modal.add-product
                @click="EditProduct(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                style="margin-left:5px;"
                @click="DeleteProduct(data.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
            <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->
          </b-table>
          <div v-if="filterProducts.length == 0" class="demo-spacing-0">
            <b-alert variant="primary" show>
              <div class="alert-body">
                <span
                  ><strong>Empty!</strong> There is no record to display..</span
                >
              </div>
            </b-alert>
          </div>
        </b-card>
      </div>

      <b-row class="mt-2" v-if="itemView == 'grid-view'">
        <b-col
          v-for="product in filterProducts"
          v-bind:key="product.id"
          lg="3"
          cols="12"
          md="4"
          sm="12"
        >
          <!--<b-card-group deck v-for="p in myObj" :key="p.name">-->
          <a v-b-modal.add-product @click="EditProduct(product)">
            <b-card
              :img-src="product.cover"
              img-top
              img-alt="card img"
              img-height="250px"
              border-variant="light"
              v-if="productEmpty == false"
            >
              <b-row>
                <b-col md="12">
                  <b-card-text> SKU #:{{ product.sku }} </b-card-text>
                </b-col>
              </b-row>

              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text>
                    <b> {{ product.name }} </b>
                  </b-card-text>
                </b-col>
              </b-row>

              <b-row style="margin-top: 5px">
                <b-col md="12">
                  <b-card-text>
                    {{ product.category }} -> {{ product.subcategory }}
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row style="margin-top: 5px">
                <b-col md="9">
                  <b-card-text> AED {{ product.price }} </b-card-text>
                </b-col>
              </b-row>

              <b-row style="margin-left: 0px">
                <b-col
                  style="margin-inline: 3px"
                  cols="*"
                  v-for="i in product.sizes.split(',')"
                  :key="i"
                >
                  <b-badge style="margin-left: 0px" variant="primary">
                    <span> {{ i }} </span>
                  </b-badge>
                </b-col>
              </b-row>
              <b-row style="margin-top: 10px">
                <b-col md="9">
                  <b-card-text>
                    <b-badge
                      v-if="product.status == 'Approved'"
                      variant="success"
                      class="badge-glow"
                    >
                      Approved
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Pending'"
                      variant="secondary"
                      class="badge-glow"
                    >
                      Pending
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Suspended'"
                      variant="warning"
                      class="badge-glow"
                    >
                      Suspended
                    </b-badge>
                    <b-badge
                      v-if="product.status == 'Denied'"
                      variant="danger"
                      class="badge-glow"
                    >
                      Denied
                    </b-badge>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card>
          </a>

          <!--</b-card-group>-->
        </b-col>

        <b-col
          cols="12"
          v-if="filterProducts.length == 0"
          class="demo-spacing-0"
        >
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span
                ><strong>Empty!</strong> There is no record to display..</span
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
        "
      >
        <!-- <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination> -->
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BCardBody,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BFormTextarea,
  BMedia,
  BMediaAside,
  BButton,
  BPagination,
  BDropdown,
  BDropdownItem,
  BAlert,
  BSpinner,
  BCardImg,
  BImg,
  BFormRadioGroup,
  BFormRadio,
  BAvatar,
  BTable,
  BLink,
  BBadge,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import axios from '@axios';
import { BFormSelect } from 'bootstrap-vue';
import ProductAdd from '../products-add/ProductAdd.vue';
import VueGallery from 'vue-gallery';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  props: ['title'],
  components: {
    // flatPickr,
    vSelect,
    BTable,
    //VueGoodTable,
    //BAvatar,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormInput,
    BCard,
    BCardText,
    BRow,
    BFormGroup,
    //BMedia,
    BSpinner,
    BBadge,
    BImg,
    BLink,
    //BMediaAside,
    //BCardBody,
    BFormRadioGroup,
    BFormRadio,
    BCol,
    BButton,
    // BPagination,
    //BDropdown,
    //BDropdownItem,
    BCardImg,
    //BFormSelect,
    BAlert,
    BSpinner,
    gallery: VueGallery,
  },

  created() {
    this.LoadData();
  },
  computed: {
    filterProducts: function () {
      return this.myProducts.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {},
  data() {
    return {
      imgerr: false,
      dataloading: false,
      plus: '',
      moreloading: false,
      logoloading: false,
      mysubCategories: [],
      categories: [],
      subCategories: [],
      deleteObj: {
        productIds: [],
        isDeleted: '',
      },
      statusObj: {
        productIds: [],
        status: '',
      },

      selected: [],
      rows: [],
      columns: [
        // {
        //   label: '',
        //   key: 'selected',
        // },
        {
          label: '',
          key: 'cover',
        },
        {
          label: 'Name',
          key: 'name',
        },

        {
          label: 'Price',
          key: 'price',
        },
        {
          label: 'Sizes / Variants',
          key: 'sizes',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: '',
          key: 'actions',
        },
      ],
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      itemView: 'list-view',
      searchQuery: '',
      loading: false,
      filterData: '',
      date: {
        range: '',
      },
      detailObj: {},
      index: null,
      rangeDate: null,
      productfilterOptions: [
        'Approved',
        'Suspended',
        'Pending'
      ],
      cakeLayer: [],
      myProducts: [],
      productEmpty: false,
      myObj: {
        id: 0,
        name: '',
        description: '',
        sku: '',
        images: [],
        sizes: [],
        price: 0,
        fittingRoom: false,
        categoryId: 0,
        subcategoryId: 0,
        vendorId: '',
        status: 'Pending',
      },
      cakeImages: [],
      flavour: [],
      cakeSize: [],
    };
  },
  methods: {
    CheckDescription() {
      var elem = this.$refs['desc'];
      if (this.myObj.description == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSizes() {
      var elem = this.$refs['sizes'];
      if (this.myObj.sizes.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSKU() {
      var elem = this.$refs['sku'];
      if (this.myObj.sku == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPicture() {
      console.log(this.myObj.images.length);
      if (this.myObj.images.length == 0) {
        return (this.imgerr = true);
      } else {
        return (this.imgerr = false);
      }
    },
    CheckName() {
      var elem = this.$refs['name'];
      if (this.myObj.name == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCategory() {
      var elem = this.$refs['category'];
      if (this.myObj.categoryId == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSubCategory() {
      var elem = this.$refs['subcategory'];
      if (this.myObj.subcategoryId == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPrice() {
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs['price'];
      if (regex.test(this.myObj.price)) {
        if (this.myObj.price == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    EmptyForm() {
      if (this.$store.state.userData.service == 'Bronze') {
        if (this.myProducts.length < 5) {
          this.myObj.id = 0;
          this.myObj.name = '';
          this.myObj.images = [];
          this.myObj.price = 0;
          this.myObj.fittingRoom = false;
          this.myObj.sizes = [];
          this.myObj.sku = '';
          this.myObj.description = '';
          this.myObj.categoryId = 0;
          this.myObj.subcategoryId = 0;
          this.myObj.vendorId = 0;
          this.$bvModal.show('add-product');
        } else {
          Swal.fire(
            'Limit Exceeded!',
            'Please upgrade your package to upload more products.',
            'warning',
          );
        }
      } else {
        this.myObj.id = 0;
        this.myObj.name = '';
        this.myObj.images = [];
        this.myObj.price = 0;
        this.myObj.fittingRoom = false;
        this.myObj.sizes = [];
        this.myObj.sku = '';
        this.myObj.description = '';
        this.myObj.categoryId = 0;
        this.myObj.subcategoryId = 0;
        this.myObj.vendorId = 0;
        this.$bvModal.show('add-product');
      }
    },
    EditProduct(row) {
      this.myObj.id = row.id;
      this.myObj.name = row.name;
      this.myObj.images = row.images.split(',');
      this.myObj.price = row.price;
      this.myObj.fittingRoom = row.fittingRoom;
      this.myObj.sizes = row.sizes.split(',');
      this.myObj.sku = row.sku;
      this.myObj.description = row.description;
      this.myObj.categoryId = row.categoryId;
      this.myObj.subcategoryId = row.subcategoryId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.status = row.status;

      this.LoadsubCategory();
      console.log(row);
    },
    checkCategory() {
      if (this.myObj.categoryId == 0 || this.myObj.categoryId == null) {
        return (this.errors.category = true);
      } else {
        return (this.errors.category = false);
      }
    },

    AddProduct() {
      this.CheckCategory();
      this.CheckDescription();
      this.CheckSizes();
      this.CheckSKU();
      this.CheckSubCategory();
      this.CheckName();
      this.CheckPrice();
      this.CheckPicture();
      if (
        this.CheckCategory() == false ||
        this.CheckDescription() == false ||
        this.CheckSizes() == false ||
        this.CheckSKU() == false ||
        this.CheckSubCategory() == false ||
        this.CheckName() == false ||
        this.CheckPrice() == false ||
        this.CheckPicture() == true
      ) {
        return 0;
      } else {
          var sendObj =
          {
        id: this.myObj.id,
        name: this.myObj.name,
        description: this.myObj.description,
        sku: this.myObj.sku,
        images:  this.myObj.images.toString(),
        sizes: this.myObj.sizes.toString(),
        price: parseInt(this.myObj.price),
        fittingRoom: this.myObj.fittingRoom,
        categoryId:this.myObj.categoryId,
        subcategoryId: this.myObj.subcategoryId,
        vendorId:  this.$store.state.userData.userID,
        status: this.myObj.status,
          }
      if (this.myObj.id == 0) {
          //return console.log(this.myObj);
      
          // this.myObj.price = parseInt(this.myObj.price);
          // this.myObj.sizes = this.myObj.sizes.toString();
          // this.myObj.images = this.myObj.images.toString();
          // this.myObj.vendorId = this.$store.state.userData.userID;
          if (this.$store.state.userData.service == 'Bronze') {
            if (this.myProducts.length < 5) {
              console.log(this.myObj);
              var axios = require('axios');

              var config = {
                method: 'post',
                url: 'https://brandaway.fastech.pk/api/Products/AddProduct',
                headers: {
                  Authorization: 'Bearer ' + this.$store.state.userData.token,
                  'Content-Type': 'application/json',
                },
                data: sendObj,
              };
              axios(config)
                .then((response) => {
                  console.log(response.data);
                  if (response.data.status == 'success') {
                    this.$bvModal.hide('add-product');
                    this.LoadData();
                    this.$bvToast.toast('Product Added.', {
                      title: 'Success!',
                      variant: 'success',
                      toaster: 'b-toaster-bottom-center',
                    });
                  }
                  else if(response.data.status == 'already exist')
                  {
                      this.$bvToast.toast('This SKU no is already exist.', {
                      title: 'Error!',
                      variant: 'danger',
                      toaster: 'b-toaster-bottom-center',
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              Swal.fire(
                'Limit Exceeded!',
                'Please upgrade your package to upload more products.',
                'warning',
              );
            }
          } else {
            console.log(this.myObj);
            var axios = require('axios');

            var config = {
              method: 'post',
              url: 'https://brandaway.fastech.pk/api/Products/AddProduct',
              headers: {
                Authorization: 'Bearer ' + this.$store.state.userData.token,
                'Content-Type': 'application/json',
              },
              data: sendObj,
            };
            axios(config)
              .then((response) => {
                //console.log(response.data);
                if (response.data.status == 'success') {
                    this.$bvModal.hide('add-product');
                  this.LoadData();
                  this.$bvToast.toast('Product Added.', {
                    title: 'Success!',
                    variant: 'success',
                    toaster: 'b-toaster-bottom-center',
                  });
                }
                else if(response.data.status == 'already exist')
                  {
                      this.$bvToast.toast('This SKU no is already exist.', {
                      title: 'Error!',
                      variant: 'danger',
                      toaster: 'b-toaster-bottom-center',
                    });
                  }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } else if (this.myObj.id != 0) {
          // this.myObj.price = parseInt(this.myObj.price);
          // this.myObj.sizes = this.myObj.sizes.toString();
          // this.myObj.images = this.myObj.images.toString();
          // this.myObj.vendorId = this.$store.state.userData.userID;
          console.log(this.myObj);
          var axios = require('axios');

          var config = {
            method: 'put',
            url: 'https://brandaway.fastech.pk/api/Products/UpdateProduct',
            headers: {
              Authorization: 'Bearer ' + this.$store.state.userData.token,
              'Content-Type': 'application/json',
            },
            data: sendObj,
          };
          axios(config)
            .then((response) => {
              //console.log(response.data);
              if (response.data.status == 'success') {
                this.$bvModal.hide('add-product');
                this.LoadData();
                this.$bvToast.toast('Product Updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              }
              else if(response.data.status == 'already exist')
                  {
                      this.$bvToast.toast('This SKU no is already exist.', {
                      title: 'Error!',
                      variant: 'danger',
                      toaster: 'b-toaster-bottom-center',
                    });
                  }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },

    LoadsubCategory() {
      this.mysubCategories = [];
      this.subCategories.forEach((e) => {
        if (e.categoryId == this.myObj.categoryId) {
          this.mysubCategories.push(e);
        }
      });
      console.log(this.mysubCategories);
    },
    rowClass(item, type) {
      const colorClass = 'table-secondary';
      if (!item || type !== 'row') {
        return;
      }

      // eslint-disable-next-line consistent-return
      //console.log("Hello", item);
      if (item.status == 'inactive') {
        return colorClass;
      }
    },
    DeletedAll(deleted) {
      this.deleteObj.productIds = [];
      this.selected.forEach((elem) => {
        this.deleteObj.productIds.push(elem.productId);
      });
      this.deleteObj.isDeleted = deleted;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');

          var config = {
            method: 'put',
            url: 'https://okaaik.fastech.pk/api/products/RecentlyDelete',
            headers: {
              Authorization:
                'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
            },
            data: this.deleteObj,
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === 'success') {
                Swal.fire(
                  'Deleted!',
                  'Your products has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else if (response.data.status == 'exist') {
                Swal.fire(
                  'Not Allowed!',
                  'You cannot delete </br>' +
                    " ' " +
                    response.data.data +
                    " ' " +
                    '</br> these has orders.',
                  'warning',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else {
                Swal.fire('Error!', 'Something went wrong.', 'error');
              }

              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    ActiveAll(status) {
      this.statusObj.productIds = [];
      this.selected.forEach((elem) => {
        this.statusObj.productIds.push(elem.productId);
      });
      this.statusObj.status = status;
      ///console.log()
      var axios = require('axios');

      var config = {
        method: 'post',
        url: 'https://okaaik.fastech.pk/api/products/ActiveAll',
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
        data: this.statusObj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status == 'success') {
            this.LoadData();
            this.$bvToast.toast('Status updated.', {
              title: 'Success!',
              variant: 'success',
              toaster: 'b-toaster-bottom-center',
            });
          }
          console.log(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onRowSelected(items) {
      this.selected = items;
      console.log(this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // AddProduct()
    // {
    //   this.$store.state.userData.productId = 0;
    //   console.log("storeId", this.$store.state.userData.productId);
    //   this.$router.push('/apps/products/add');

    // },
    ProductEdit(id) {
      this.$store.state.userData.productId = id;

      console.log('storeId', this.$store.state.userData.productId);
      this.$router.push('/apps/products/add');
    },
    Filter() {
       console.log(this.filterData);
        var axios = require('axios');
      var screen = {
        method: 'post',
        url:
          'https://brandaway.fastech.pk/api/Products/VendorFilter',
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
        data:{vendorId:this.$store.state.userData.userID,status:this.filterData}
      };

      axios(screen)
        .then((response) => {
          console.log(response.data);
          this.myProducts = response.data.data;
          console.log(this.myProducts);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    ProductDetails(id) {
      this.loading = true;
      this.cakeImages = [];
      this.cakeLayers = [];
      this.cakeSize = [];
      this.flavour = [];
      console.log(id);
      var axios = require('axios');

      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/products/GetproductbyId/' + id,
        headers: {
          Authorization:
            'bearer 56EeZXfkyt2E4yYawlxCo3FFMZiFmhDs2VZbA9jLIfTs70L_z5gC9yAMCchwnNRUcicotXJNc3GcSB8M-T9i8PLd-HUMk4KXom3Uu_kC4kbFhSk02Z9YQRwZqVdXjECBVCA0gOhnVoRLztCFQxNgVJbeWEMubqzh8dFpvnkIoC_2FF0kyCzKbwj4SYtF1fMMyhy2CO8DOIWUTZtdpwpI-IjGB0CyHhav_I_ag72apOUtKKhAoshLdYfcFz3QBYC0Pj-FIyEbzz_S5Rcp9fiIG3y5UM6RtSf8zP08M42rVjkLHwOb5cc8YafdfFExhGFI',
        },
      };

      axios(config)
        .then((response) => {
          //this.cakeImages.push(response.data.images);
          console.log(response.data);
          this.cakeImages = [];
          response.data.images.forEach((elem) => {
            this.cakeImages.push(elem.imagePath);
          });
          this.cakeSize.push(response.data.cakesize);
          this.flavour.push(response.data.flavours);
          this.detailObj = response.data.data[0];
          this.cakeLayers = response.data.cakelayer;
          console.log('Mylayers', this.cakeLayers);
          console.log(this.detailObj);
          console.log(this.cakeImages);
          console.log(this.cakeSize[0]);

          console.log(this.flavour[0]);
          this.loading = false;
          //console.log( response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    DeleteProduct(id) {
      /// return console.log(this.deleteObj);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');

          var config = {
            method: 'delete',
            url: 'https://brandaway.fastech.pk/api/Products/' + id,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === 'success') {
                Swal.fire(
                  'Deleted!',
                  'Your product has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else if (response.data.status == 'exist') {
                Swal.fire(
                  'Not Allowed!',
                  'You cannot delete </br>' +
                    " ' " +
                    response.data.data +
                    " ' " +
                    '</br> it has orders.',
                  'warning',
                ).then((res) => {
                  this.LoadData();
                  this.$bvModal.hide('modal-select2');
                });
              } else {
                Swal.fire('Error!', 'Something went wrong.', 'error');
              }

              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },

    removeImg(index) {
      console.log(index);
      this.myObj.images.splice(index, 1);
      this.$refs.mfile.value=null;
      this.CheckPicture();
    },

    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append('file', this.$refs.mfile.files[i]);
        }

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };

        fetch('https://upload.appick.io', requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.myObj.images.push(fn);
            }
            this.CheckPicture();
            console.log(this.myObj.images);
          })

          .catch((error) => console.log('error', error));
      }
    },
    LoadData() {
      var axios = require('axios');

      var screen = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Products/LoadProductScreen/' +
          this.$store.state.userData.userID +
          '/' +
          this.$store.state.userData.type,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(screen)
        .then((response) => {
          console.log(response.data);

          //  this.data  =  response.data;
          //console.log( response.data);

          this.myProducts = response.data.products;
          this.categories = response.data.categories;
          this.subCategories = response.data.subCategories;

          //  this.rows = response.data.data;

          console.log(this.myProducts);
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin: 5px;
}
</style>

